export default (monaco: any) => [
    {
        label: 'GetValue(String key)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetValue(${1:key})',
        detail: '直接获取属性值\n' +
            '返回key对应值'
    }, {
        label: 'SetProp(String key,object keyValue)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'SetProp(${1:key},${2:keyValue})',
        detail: '直接设置属性值\n' +
            '返回无'
    }
]
