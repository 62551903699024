export default (monaco: any) => [
    {
        label: 'Name',
        kind: monaco.languages.CompletionItemKind.Value,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'Name',
        detail: '获取节点名',
    }, {
        label: 'ID',
        kind: monaco.languages.CompletionItemKind.Value,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'ID',
        detail: '获取节点ID,唯一标识节点对象'
    }, {
        label: 'Type',
        kind: monaco.languages.CompletionItemKind.Value,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'Type',
        detail: '获取节点类型'
    }, {
        label: 'Parent',
        kind: monaco.languages.CompletionItemKind.Value,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'Parent',
        detail: '获取父层节点'
    }, {
        label: 'GetChild(String name, string strNodeType, bool autoCreate = false)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetChild(${1:name},${2:strNodeType},${3:autoCreate})',
        detail: '通过名称与类型查找子节点\n' +
            '参数:\n' +
            'name:子节点名\n' +
            'strNodeType:节点类型字符串; \n' +
            'autoCreate:true表示若不存在则创建，false表示若不存在则不创建\n' +
            '\n' +
            '返回：\n' +
            '找到子节点则返回NodeTemplateMaker,反之则返回null。\n\n' +
            '备注：调用该函数关机后部分光机将进入冷却关机处理，光机并不会立即断开电源。'
    }, {
        label: 'GetChild(String name)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetChild(${1:name})',
        detail: '通过名称获取子节点\n' +
            '参数:\n' +
            'name:子节点名\n' +
            '\n' +
            '返回：\n' +
            '找到子节点则返回NodeTemplateMaker,反之则返回null。\n'
    }, {
        label: 'GetAttr(String attrName)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetAttr(${1:attrName})',
        detail: '获取属性\n' +
            '参数:\n' +
            'attrName：属性名\n' +
            '\n' +
            '返回：类型object\n' +
            '找到属性对象则返回属性对象object,反之则返回null。\n'
    }, {
        label: 'CreateChild(String name, string strNodeType, bool notify = true)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'CreateChild(${1:name},${2:strNodeType},${3:notify})',
        detail: '创建子节点\n' +
            '参数:\n' +
            'name:子节点名\n' +
            'strNodeType:节点类型字符串; \n' +
            'notify(可选):是否发送变更通知，true：发送，否则不发送仅清空变更记录\n' +
            '\n' +
            '返回：\n' +
            '创建子节点成功则返回NodeTemplateMaker对象,反之则返回null。\n'
    }, {
        label: 'RemoveChild(String name, bool notify = true)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'RemoveChild(${1:name},${2:notify})',
        detail: '删除子节点\n' +
            '参数:\n' +
            'name:子节点名\n' +
            'notify(可选):是否发送变更通知，true：发送，否则不发送仅清空变更记录\n' +
            '\n' +
            '返回：\n' +
            '删除子节点成功则返回NodeTemplateMaker对象,反之则返回null。\n'
    }, {
        label: 'Rename(String newName, bool notify = true)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'Rename(${1:newName},${2:notify})',
        detail: '节点重命名\n' +
            '参数:\n' +
            'name:子节点名\n' +
            'notify(可选):是否发送变更通知，true：发送，否则不发送仅清空变更记录\n' +
            '\n' +
            '返回：\n' +
            '重命名成功则返回true,反之则返回false。\n'
    }
]
