export default (monaco: any) => [
    {
        label: 'TypeInfo',
        kind: monaco.languages.CompletionItemKind.Value,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'TypeInfo',
        detail: '获取通讯类型信息.'
    }, {
        label: 'IsOpen',
        kind: monaco.languages.CompletionItemKind.Value,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'IsOpen',
        detail: '通讯是否打开状态.\n\n' +
            '返回:\n' +
            'bool状态值，ture表示连接打开，false表示未打开'
    },
    {
        label: 'GetCurrConfigData()',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetCurrConfigData()',
        detail: '获取当前通讯配置信息.\n' +
            '返回：类型PropList\n' +
            '返回配置信息列表PropList对象。\n\n' +
            '如:Switch(1,[0,1,2])\n' +
            '输入信号列表序列号1的信号切换到输出信号列表序列号为0，1，2的输出口.\n' +
            'Switch(0,4)输入信号序列0的信号切换到输出4'
    },
    {
        label: 'Open()',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'Open()',
        detail: '打开通讯.\n' +
            '返回：类型bool\n' +
            '返回ture表示打开成功，false表示打开失败\n'
    },
    {
        label: 'Close()',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'Close()',
        detail: '关闭通讯，（必须释放通讯对象）.\n' +
            '返回：类型bool\n' +
            '返回ture表示关闭成功，false表示关闭失败\n'
    },
    {
        label: 'PurgeReciveComm()',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'PurgeReciveComm()',
        detail: '清空接收缓冲区'
    },
    {
        label: 'RecvData(object stopFlagByts, int timeout)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'RecvData(${1:stopFlagByts},${2:timeout})',
        detail: '获取数据一直到接受数据与停止标识字节队列匹配，否则一直接受直到超时\n' +
            '参数:\n' +
            'stopFlagByts:停止接受标识字节队列，如果stopFlagByts为null，则不用判断结束符号\n' +
            'timeout:超时时间，如果为小于等于0，使用默认配置的超时；如果大于0，使用指定超时\n' +
            '\n' +
            '返回：类型 byte[]\n' +
            '接收到的数据流。\n'
    },
    {
        label: 'RecvData(int len, int timeout)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'RecvData(${1:len},${2:timeout})',
        detail: '获取数据一直到指定数据长度或超时\n' +
            '参数:\n' +
            'len:固定接受的数据长度，如果len小于等于0，根据实际情况接受内容，不固定接收长度\n' +
            'timeout:超时时间，如果为小于等于0，使用默认配置的超时；如果大于0，使用指定超时\n' +
            '\n' +
            '返回：类型 byte[]\n' +
            '接收到的数据流。\n'
    },
    {
        label: 'SendData(object sendbytes, int pos, int len)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'SendData(${1:sendbytes},${2:pos},${3:len})',
        detail: '发送数据\n' +
            '参数:\n' +
            'sendbytes:发送的数据字节队列\n' +
            'pos: 队列中发送开始位置\n' +
            'len:发送数据长度\n' +
            '\n' +
            '返回：类型 int\n' +
            '返回实际发送数据长度。\n'
    },
    {
        label: 'SendAndRecv(object sendbytes, int sendPos, int sendLen, object recvStopFlagByts, int timeout = -1)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'SendAndRecv(${1:sendbytes},${2:sendPos},${3:sendLen},${4:recvStopFlagByts},${5:timeout})',
        detail: '发送数据并立即接收数据\n' +
            '参数:\n' +
            'sendbytes:发送的数据字节队列\n' +
            'sendPos: 队列中发送开始位置\n' +
            'sendLen:发送数据长度\n' +
            'recvStopFlagByts:停止接受标识字节队列，如果stopFlagByts为null，则不用判断结束符号\n' +
            'timeout:如果为小于等于0，使用默认配置的超时；如果大于0，使用指定超时\n' +
            '\n' +
            '返回：类型 byte[]\n' +
            '返回接收到的数据数组。\n'
    },
    {
        label: 'SendAndRecv(object sendbytes, int sendPos, int sendLen, int recvLen = 0, int timeout = -1)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'SendAndRecv(${1:sendbytes},${2:sendPos},${3:sendLen},${4:recvLen},${5:timeout})',
        detail: '发送数据并立即接收数据\n' +
            '参数:\n' +
            'sendbytes:发送的数据字节队列\n' +
            'sendPos: 队列中发送开始位置\n' +
            'sendLen:发送数据长度\n' +
            'recvLen: 固定接受的数据长度，如果len小于等于0，根据实际情况接受内容，不固定接收长度\n' +
            'timeout:如果为小于等于0，使用默认配置的超时；如果大于0，使用指定超时\n' +
            '\n' +
            '返回：类型 byte[]\n' +
            '返回接收到的数据数组。\n'
    },
    {
        label: 'ResetInnerObject(IComm newCommObject)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'ResetInnerObject(${1:newCommObject})',
        detail: '替换内嵌对象\n' +
            '参数:\n' +
            'newCommObject:新的通讯内核对象\n' +
            '返回：类型 int\n' +
            '返回原来的通讯对象。\n'
    },
    {
        label: 'SetRecvTimeout(int timeout)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'SetRecvTimeout(${1:timeout})',
        detail: '用于设置接收数据超时时间\n' +
            '参数:\n' +
            'timeout:超时时间，单位ms\n' +
            '返回：类型 int\n' +
            '返回之前设置的超时时间，返回-1不支持设置超时，返回-2设置失败.\n'
    },
    {
        label: 'ReceiveMessage(string encoding = null, int outTime = 0)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'ReceiveMessage(${1:encoding},${2:outTime})',
        detail: '接收消息\n' +
            '参数:\n' +
            'encoding:指定编码格式，若空则按默认编码 ，若不符编码格式规则，则返回fasle\n' +
            'outTime: 接收超时时间\n' +
            '\n' +
            '\n' +
            '返回：类型 string\n' +
            '返回接收到按编码格式的字符串。\n'
    },
    {
        label: 'SendMessage(string msg, string encoding = null)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'SendMessage(${1:msg},${2:encoding})',
        detail: '发送消息\n' +
            '参数:\n' +
            'msg:发送的消息字符串\n' +
            'encoding: 指定编码格式，若空则按默认编码 ，若不符编码格式规则，则返回fasle\n' +
            '\n' +
            '\n' +
            '返回：类型 bool\n' +
            'ture：发送成功；false:发送失败。\n'
    }
]
