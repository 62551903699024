import {createStore} from 'vuex'

export default createStore({
    state: {
        configParams: {},
        configFormState: {},
    },
    mutations: {
        setConfigParams(state, params) {
            state.configFormState = {};
            state.configParams = params;
        }
    },
    actions: {},
    modules: {},
    getters: {
        getConfigParams(state) {
            return state.configParams;
        },
        getConfigFormState(state) {
            return state.configFormState;
        }
    },
})
