export default (monaco: any) => [
    {
        label: 'Info(object message)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'Info(${1:message})',
        detail: '输出信息log'
    }, {
        label: 'Debug(object message)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'Debug(${1:message})',
        detail: '输出调试log'
    }, {
        label: 'Error(object message)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'Error(${1:message})',
        detail: '输出错误log'
    }
]
