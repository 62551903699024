export default (monaco: any) => [
    {
        label: 'GetFileStream(string filePath)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetFileStream(${1:filePath})',
        detail: '获取文件字节流.\n' +
            '参数:\n' +
            'filePath：文件全路径字符串\n' +
            '返回：类型byte[]\n' +
            '返回文件字节流byte[]。\n'
    }
]
