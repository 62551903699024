<template>
  <a-config-provider :locale="locale">
    <router-view/>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
import "./utils/common.less"
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
  height: 100%;
}

*::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 8px;
  background-color: #31313d !important;
}

*::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  cursor: pointer;
  display: block;
  position: relative;
  background: linear-gradient(rgb(190, 46, 228) 0%, rgb(0, 163, 255) 100%);
}

//页面100%撑满，不滚动
.fullMain{
  position: absolute;
  height: calc(100% - 40px);
  width: calc(100% - 100px);
}
</style>
