export default (monaco: any) => [
    {
        label: 'ContentType',
        kind: monaco.languages.CompletionItemKind.Value,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'ContentType',
        detail: '文本类型.\n\n' +
            '类型：string，文本类型application/xml、application/json、application/text、application/x-www-form-urlencoded'
    }, {
        label: 'Timeout',
        kind: monaco.languages.CompletionItemKind.Value,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'Timeout',
        detail: '超时时间（单位ms）. 类型：int，默认值1000'
    },
    {
        label: 'SetHeader(string key, string value)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'SetHeader(${1:key},${2:value})',
        detail: '设置Http头，键值对多对时可多次设置,调用Get/Post请求前可设置http头.\n' +
            '参数:\n' +
            'key:头信息参数\n' +
            'value:头信息参数对应值\n' +
            '返回：无\n'
    },
    {
        label: 'ClearHeader()',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'ClearHeader()',
        detail: '清空Header'
    },
    {
        label: 'SetRequestEncoding(String encoding)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'SetRequestEncoding(${1:encoding})',
        detail: '设置请求编码,如果ContextType中指定了编码，则必须一致\n' +
            '参数:\n' +
            'encoding:请求编码格式，类型：string\n' +
            '\n' +
            '返回：无\n'
    },
    {
        label: 'SeResponesEncoding(String encoding)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'SeResponesEncoding(${1:encoding})',
        detail: '设置响应编码\n' +
            '参数:\n' +
            'encoding:请求编码格式，类型：string\n' +
            '\n' +
            '返回：无\n'
    },
    {
        label: 'Post(string url, string postData = null)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'Post(${1:url},${2:postData})',
        detail: '发起POST同步请求\n' +
            '参数:\n' +
            'url:地址\n' +
            'postData:发给服务器的参数请求体数据字符串\n' +
            '返回：类型 string\n' +
            '返回请求结果。'
    },
    {
        label: 'PostAsync(string url, string postData = null)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'PostAsync(${1:url},${2:postData})',
        detail: '发起POST异步请求\n' +
            '参数:\n' +
            'url:地址\n' +
            'postData:发给服务器的参数请求体数据字符串\n' +
            '返回：无'
    },
    {
        label: 'Get(string url)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'Get(${1:url})',
        detail: '发起GET同步请求\n' +
            '参数:\n' +
            'url:地址\n' +
            '返回：类型 string\n' +
            '返回请求结果。'
    },
    {
        label: 'HttpGetAsync(string url)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'HttpGetAsync(${1:url})',
        detail: '发起GET异步请求\n' +
            '参数:\n' +
            'url:地址\n' +
            '返回：类型 string\n' +
            '返回请求结果。'
    },
    {
        label: 'Release()',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'Release()',
        detail: '释放对象'
    }
]
