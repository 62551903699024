export default (monaco: any) => [
    {
        label: 'GetCurTime()',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetCurTime()',
        detail: '获取当前时间\n' +
            '参数:\n' +
            '无\n' +
            '返回：DateTime，当前时间对象类型\n'
    }, {
        label: 'GetCurTimeString()',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetCurTimeString()',
        detail: '获取当前时间\n' +
            '参数:\n' +
            '无\n' +
            '返回：string类型，将当前时间转换为string类型\n'
    }, {
        label: 'ConvertTimeToString(DateTime dataTime,string convertType = null)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'ConvertTimeToString(${1:dataTime},${2:convertType})',
        detail: '将时间（DateTime类型）转换成相应格式的字符串形式\n' +
            '参数:\n' +
            'dataTime:当前时间，类型：DateTime\n' +
            'convertType:转换格式，类型：string(d:段日期，D：长日期等)\n' +
            '返回：转换后的时间，类型：string\n'
    }, {
        label: 'TimeSpan(DateTime endTime,DateTime startTime)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'TimeSpan(${1:endTime},${2:startTime})',
        detail: '计算时间差\n' +
            '参数:\n' +
            'endTime:结束时间，类型：DateTime\n' +
            'startTime:开始时间，类型：DateTime\n' +
            '返回：时间差，类型string\n'
    }
]
