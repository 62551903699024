import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    redirect:"/device",
    component: () => import('@/views/home/index.vue'),
    children:[
      {
        path: '/x2server',
        name: '/x2server',
        meta:{title:'控制台'},
        component: () => import('@/views/x2server/index.vue')
      },
      {
        path: '/device',
        name: '/device',
        meta:{title:'设备管理'},
        component: () => import('@/views/device/index.vue')
      },
      {
        path: '/service',
        name: '/service',
        meta:{title:'服务管理'},
        component: () => import('@/views/service/index.vue')
      },
      {
        path: '/plugin',
        name: '/plugin',
        meta:{title:'插件库'},
        component: () => import('@/views/plugin/index.vue')
      },
      {
        path: '/script',
        name: '/script',
        meta:{title:'脚本管理'},
        component: () => import('@/views/scriptView/index.vue')
      },
      {
        path: '/task',
        name: '/task',
        meta:{title:'任务管理'},
        component: () => import('@/views/task/index.vue')
      },
      {
        path: '/user',
        name: '/user',
        meta:{title:'用户管理'},
        component: () => import('@/views/user/index.vue')
      },
      {
        path: '/systemInfo',
        name: '/systemInfo',
        meta:{title:'系统信息'},
        component: () => import('@/views/systemInfo/index.vue')
      },
      {
        path: '/configurationView',
        name:"configurationView",
        meta:{title:'配置'},
        component: () => import('@/components/common/ConfigurationView.vue')
      }
    ]
  },
  {
    path:'/login',
    component: () => import('@/views/home/Login.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.path != "/login" && sessionStorage.getItem("userId") == null){
    next("/login")
  }
  if(to.path == "/configurationView" && to.params.type == null){
    next("/")
  }
  next()
})

export default router
