export default (monaco: any) => [
    {
        label: 'DeviceName',
        kind: monaco.languages.CompletionItemKind.Value,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'DeviceName',
        detail: '设备名',
    },
    {
        label: 'Initialized',
        kind: monaco.languages.CompletionItemKind.Value,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'Initialized',
        detail: '是否初始化过\n' +
            '返回：\n' +
            'bool类型状态，ture表示初始化过，false表示未初始化'
    },
    {
        label: 'tagCount',
        kind: monaco.languages.CompletionItemKind.Value,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'tagCount',
        detail: '设备标签个数'
    },
    {
        label: 'PropTagNameList',
        kind: monaco.languages.CompletionItemKind.Value,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'PropTagNameList',
        detail: '设备属性标签名数组'
    },
    {
        label: 'DeviceDataNode',
        kind: monaco.languages.CompletionItemKind.Value,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'DeviceDataNode',
        detail: '获取设备数据节点'
    },
    {
        label: 'SupportedActions',
        kind: monaco.languages.CompletionItemKind.Value,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'SupportedActions',
        detail: '支持的动作\n' +
            '返回支持的动作数组 类型ActionID[]'
    },
    {
        label: 'CommObjct',
        kind: monaco.languages.CompletionItemKind.Value,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'CommObjct',
        detail: '获取通信对象，前提条件该设备是通过通信对象进行交互的，如果没有返回null。\n' +
            '若该设备存在内置通讯对象，则返回CommTemplateMaker对象，否则返回null；'
    },
    {
        label: 'GetActionNode(string actionName)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetActionNode(${1:actionName})',
        detail: '按名字寻找action节点。\n' +
            '参数：\n' +
            'actionName: 动作名称\n' +
            '\n' +
            '返回：\n' +
            '存在则返回NodeTemplateMaker对象，反之返回null；'
    },
    {
        label: 'DoAction(string actionID,PropList args)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'DoAction(${1:actionID},${2:args})',
        detail: '执行针对设备的动作事件，用于对设备的控制,\n' +
            '如果执行有异常通过OnAlarm事件通知详细异常内容（在特殊列表节点下谨慎使用）\n' +
            '参数：\n' +
            'actionID: 动作ID号\n' +
            'args：输入参数列表\n' +
            '\n' +
            '返回：\n' +
            '返回执行结果数据对象；'
    },
    {
        label: 'GetPropTagConfigData(string propTagName)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetPropTagConfigData(${1:propTagName})',
        detail: '获取设备属性标签配置结构\n' +
            '参数：\n' +
            'propTagName:属性标签名，如果propTagName为null默认值的数据结构框架\n' +
            '\n' +
            '返回：\n' +
            '返回属性标签配置结构PropConfigBuilder类型对象，如果propTagName不为null且未找到指定标签名的数据，则返回null；'
    },
    {
        label: 'TagCanWrite(string propTagName)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'TagCanWrite(${1:propTagName})',
        detail: '获取标签是否可写属性\n' +
            '参数：\n' +
            'propTagName:属性标签名，如果propTagName为null则返回false\n' +
            '\n' +
            '返回：\n' +
            '若可写返回true,不可行返回false；'
    },
    {
        label: 'SetPropTag(String propTagName, PropList propTagConfigData)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'SetPropTag(${1:propTagName},${2:propTagConfigData})',
        detail: '设置或添加设备属性标签\n' +
            '参数：\n' +
            'propTagName:属性标签名（属性标签名不允许重名），如果不存在则创建，已存在就变更\n' +
            'propTagConfigData：属性标签添加需要的配置数据\n' +
            '返回：\n' +
            'true:设置成功；false:设置失败'
    },
    {
        label: 'Init()',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'Init()',
        detail: '初始化，当创建新的驱动实例时调用\n' +
            '返回：\n' +
            'true:初始化成功；false:初始化失败'
    },
    {
        label: 'DeletePropTag(String propTagName)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'DeletePropTag(${1:propTagName})',
        detail: '删除设备属性标签\n' +
            '参数：\n' +
            'propTagName:属性标签名\n' +
            '返回：\n' +
            'true:删除成功；false:删除失败'
    },
    {
        label: 'GetTag(ulong tagID)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetTag(${1:tagID})',
        detail: '根据标签ID获取标签节点\n' +
            '参数：\n' +
            'tagID:标签ID\n' +
            '返回：\n' +
            '获取成功返回NodeTemplateMaker对象，否则返回null'
    },
    {
        label: 'GetTag(string tagName)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetTag(${1:tagName})',
        detail: '根据标签名获取标签节点\n' +
            '参数：\n' +
            'tagName:标签名称\n' +
            '返回：\n' +
            '获取成功返回NodeTemplateMaker对象，否则返回null'
    },
    {
        label: 'GetTagValue(string tagName)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetTagValue(${1:tagName})',
        detail: '获取标签值\n' +
            '参数：\n' +
            'tagName:标签名称\n' +
            '返回：类型object\n' +
            '获取成功返回标签值，否则返回null'
    },
    {
        label: 'SetTagValue(string tagName,object tagValue)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'SetTagValue(${1:tagName},${2:tagValue})',
        detail: '设置标签值\n' +
            '参数：\n' +
            'tagName:标签名称\n' +
            'tagValue：标签值\n' +
            '返回：类型object\n' +
            'true:设置成功；false:设置失败'
    },
    {
        label: 'SetTagUnit(string tagName,object tagUnit)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'SetTagUnit(${1:tagName},${2:tagUnit})',
        detail: '设置标签单位\n' +
            '参数：\n' +
            'tagName:标签名称\n' +
            'tagUnit：标签单位\n' +
            '返回：类型object\n' +
            'true:设置成功；false:设置失败'
    },
    {
        label: 'SetTagUnit(ulong tagID,object tagUnit)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'SetTagUnit(${1:tagID},${2:tagUnit})',
        detail: '设置标签单位\n' +
            '参数：\n' +
            'tagID:标签ID\n' +
            'tagUnit：标签单位\n' +
            '返回：类型object\n' +
            'true:设置成功；false:设置失败'
    },
    {
        label: 'GetTagUnit(string tagNamet)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetTagUnit(${1:tagName})',
        detail: '获取标签单位\n' +
            '参数：\n' +
            'tagName:标签名称\n' +
            '返回：类型object\n' +
            '获取成功返回标签单位，否则返回null'
    },
    {
        label: 'GetTagUnit(ulong tagID)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetTagUnit(${1:tagID})',
        detail: '获取标签单位\n' +
            '参数：\n' +
            'tagID:标签ID\n' +
            '返回：类型object\n' +
            '获取成功返回标签单位，否则返回null'
    },
    {
        label: 'GetTagData(ulong tagID)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetTagData(${1:tagID})',
        detail: '获取标签节点\n' +
            '参数：\n' +
            'tagName:标签名称\n' +
            '返回：类型object\n' +
            '获取成功返回标签节点对象NodeTemplateMaker，否则返回null'
    },
    {
        label: 'GetTagData(string tagName)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetTagData(${1:tagName})',
        detail: '获取标签节点\n' +
            '参数：\n' +
            'tagID:标签ID\n' +
            '返回：类型object\n' +
            '获取成功返回标签节点对象NodeTemplateMaker，否则返回null'
    },
    {
        label: 'GetTagOrgPath(ulong tagID,int upLayer=0)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'GetTagOrgPath(${1:tagID},${2:upLayer})',
        detail: '根据tag获取组织路径\n' +
            '参数：\n' +
            'tagID:标签ID\n' +
            'upLayer:组织路径往上几层\n' +
            '返回：类型object\n' +
            '获取节点组织路径'
    },
    {
        label: 'SetOrgTagsRead(string tagOrg, bool bRead)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'SetOrgTagsRead(${1:tagOrg},${2:bRead})',
        detail: '根据组织路径更改组织下的tag是否可读\n' +
            '参数：\n' +
            'tagOrg:组织路径\n' +
            'bRead：是否读取\n' +
            '返回：\n' +
            'true:设置成功；false:设置失败'
    },
    {
        label: 'OrgTagsIsReaded(string tagOrg)',
        kind: monaco.languages.CompletionItemKind.Method,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        insertText: 'OrgTagsIsReaded(${1:tagOrg})',
        detail: '根据组织路径得到当前路径是否读完\n' +
            '参数：\n' +
            'tagOrg:组织路径\n' +
            '返回：\n' +
            'true:读取成功；false:读取未完成'
    }
]
