import * as monaco from 'monaco-editor'
import {App} from "@vue/runtime-core";
import APPRule from "./APPRule"
import DriverTemplateMaker from "./DriverTemplateMaker";
import NodeTemplateMaker from "./NodeTemplateMaker";
import FileTemplateMaker from "./FileTemplateMaker";
import CommTemplateMaker from "./CommTemplateMaker";
import HttpTemplateMaker from "./HttpTemplateMaker";
import DataTime from "./DataTime";
import PropList from "./PropList";
import SysLogger from "./SysLogger";

export default (app: App) => {
    const provider: any = {
        provideCompletionItems: (model: any, position: any) => {
            return {
                suggestions: [{
                    label: 'APP',
                    kind: monaco.languages.CompletionItemKind.Class,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    insertText: 'APP',
                }, {
                    label: 'DriverTemplateMaker',
                    kind: monaco.languages.CompletionItemKind.Class,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    insertText: 'DriverTemplateMaker',
                }, {
                    label: 'NodeTemplateMaker',
                    kind: monaco.languages.CompletionItemKind.Class,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    insertText: 'NodeTemplateMaker',
                }, {
                    label: 'FileTemplateMaker',
                    kind: monaco.languages.CompletionItemKind.Class,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    insertText: 'FileTemplateMaker',
                }, {
                    label: 'CommTemplateMaker',
                    kind: monaco.languages.CompletionItemKind.Class,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    insertText: 'CommTemplateMaker',
                }, {
                    label: 'HttpTemplateMaker',
                    kind: monaco.languages.CompletionItemKind.Class,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    insertText: 'HttpTemplateMaker',
                }, {
                    label: 'DataTime',
                    kind: monaco.languages.CompletionItemKind.Class,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    insertText: 'DataTime',
                }, {
                    label: 'X2ServerClient(string IP, int port, string tokenID)',
                    kind: monaco.languages.CompletionItemKind.Class,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    insertText: 'X2ServerClient(${1:IP},${2:port},${3:tokenID})',
                }, {
                    label: 'PropList',
                    kind: monaco.languages.CompletionItemKind.Class,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    insertText: 'PropList',
                }, {
                    label: 'SysLogger',
                    kind: monaco.languages.CompletionItemKind.Class,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    insertText: 'SysLogger',
                }]
            };
        }
    }
    const provider2: any = {
        triggerCharacters: ["."],
        provideCompletionItems: (model: any, position: any) => {
            const textUntilPosition: any = model.getValueInRange({
                startLineNumber: 1,
                startColumn: 1,
                endLineNumber: position.lineNumber,
                endColumn: position.column
            });
            if (textUntilPosition.substring(0, 4) === "APP.") {
                return {
                    suggestions: APPRule(monaco)
                };
            }

            if(textUntilPosition.substring(0,20) === "DriverTemplateMaker."){
                return {
                    suggestions:DriverTemplateMaker(monaco)
                };
            }
            if(textUntilPosition.substring(0,18) === "NodeTemplateMaker."){
              return {
                suggestions:NodeTemplateMaker
              };
            }
            if(textUntilPosition.substring(0,18) === "FileTemplateMaker."){
              return {
                suggestions:FileTemplateMaker
              };
            }
            if(textUntilPosition.substring(0,18) === "CommTemplateMaker."){
              return {
                suggestions:CommTemplateMaker
              };
            }
            if(textUntilPosition.substring(0,18) === "HttpTemplateMaker."){
              return {
                suggestions:HttpTemplateMaker
              };
            }
            if(textUntilPosition.substring(0,22) === "DataTime."){
              return {
                suggestions:DataTime
              };
            }
            if(textUntilPosition.substring(0,9) === "PropList."){
              return {
                suggestions:PropList
              };
            }
            if(textUntilPosition.substring(0,10) === "SysLogger."){
              return {
                suggestions:SysLogger
              };
            }
        }
    }
    monaco.languages.registerCompletionItemProvider('javascript', provider)
    monaco.languages.registerCompletionItemProvider('javascript', provider2)
    app.config.globalProperties.$monacoEditor = monaco;
}
