import axios from "axios";
import {App} from "@vue/runtime-core";

const config = {
    // baseURL: "http://localhost:9001",
    // timeout: 60 * 1000
}

const Axios = axios.create(config)

//请求拦截
Axios.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
//响应拦截
Axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export default (app: App) => {
  app.config.globalProperties.$http = Axios;
}
