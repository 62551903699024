import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installAntd from "@/plugins/antd";
import installMonacoEditor from "@/plugins/monacoEditor/monacoEditor";
import installAxios from "@/plugins/axios";


const app = createApp(App)
installAntd(app)
installMonacoEditor(app)
installAxios(app)

app.use(store).use(router).mount('#app')
