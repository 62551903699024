export default (monaco: any) => [{
    label: 'Root',
    kind: monaco.languages.CompletionItemKind.Value,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'Root',
    detail: '获取数据根对象,返回根节点Data对象',
}, {
    label: 'AppPath',
    kind: monaco.languages.CompletionItemKind.Value,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'AppPath',
    detail: '获取程序路径'
}, {
    label: 'AppVersion',
    kind: monaco.languages.CompletionItemKind.Value,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'AppVersion',
    detail: '获取程序版本号'
}, {
    label: 'AppName',
    kind: monaco.languages.CompletionItemKind.Value,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'AppName',
    detail: '获取主程序名称'
}, {
    label: 'SaveData()',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'SaveData()',
    detail: '保存数据到默认数据路径下,保存失败将抛出异常'
}, {
    label: 'GetSysRoot()',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'GetSysRoot()',
    detail: '获取系统根节点.\n' +
        '返回：获取成功返回NodeTemplateMaker对象；反之返回null.'
}, {
    label: 'GetDataNode(dataPath: string)',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'GetDataNode(${1:dataPath})',
    detail: '根据节点路径获取节点对象.\n' +
        '参数：\n' +
        'dataPath为节点路径\n' +
        '\n' +
        '返回：\n' +
        '创建成功返回NodeTemplateMaker对象；反之返回null。'
}, {
    label: 'GetDeviceListData()',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'GetDeviceListData()',
    detail: '获取设备列表数据的根节点.\n' +
        '返回：\n' +
        '创建成功返回NodeTemplateMaker对象；反之返回null。'
}, {
    label: 'GetDeviceOrgData()',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'GetDeviceOrgData()',
    detail: '获取设备组织结构树的根节点.\n' +
        '返回：\n' +
        '创建成功返回NodeTemplateMaker对象；反之返回null。'
}, {
    label: 'GetMainAppConfigData()',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'GetMainAppConfigData()',
    detail: '获取主程序配置数据节点.\n' +
        '返回：\n' +
        '创建成功返回NodeTemplateMaker对象；反之返回null。'
}, {
    label: 'GetServicesConfigData()',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'GetServicesConfigData()',
    detail: '获取服务配置管理数据节点.\n' +
        '返回：\n' +
        '创建成功返回NodeTemplateMaker对象；反之返回null。'
}, {
    label: 'RunExe(bSync: boolean,strExePath: string,strParam: string,nTimeOut: number)',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'RunExe(${1:bSync},${2:strExePath},${3:strParam},${4:nTimeOut})',
    detail: '运行外部程序.\n' +
        '参数：\n' +
        'bSync为是否同步运行标志\n' +
        'strExePath为外部程序的全路径 \n' +
        'strParam为外部程序的字符串参数\n' +
        'nTimeOut为外部程序运行最长时间，超过该时长自动关闭(可选)\n' +
        '\n' +
        '超时时间未指定或者负数表示外部程序由其自身决定关闭\n' +
        '\n' +
        '返回：\n' +
        '若同步运行返回外部程序的运行结果，失败则返回null；若异步运行，则返回null。'
}, {
    label: 'GetDriver(deviceName: string)',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'GetDriver(${1:deviceName})',
    detail: '根据设备名称和设备类型获取已创建的设备对象.\n' +
        '参数：\n' +
        'deviceName为设备名称字符串\n' +
        '\n' +
        '\n' +
        '返回：\n' +
        '若找到设备则返回DriverTemplateMaker对象，失败则返回null。'
}, {
    label: 'CreateDriver(deviceName: string,typeName: string,args: PropList)',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'CreateDriver(${1:deviceName},${2:typeName},${3:args})',
    detail: '创建设备对象.\n' +
        '参数：\n' +
        'deviceName为设备名称字符串\n' +
        'typeName为设备类型字符串\n' +
        'args为PropList类型的配置参数\n' +
        '返回：\n' +
        '若创建成功则返回DriverTemplateMaker对象，失败则返回null。'
}, {
    label: 'GetDriverModelPropList(deviceTypeName: string)',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'GetDriverModelPropList(${1:deviceTypeName})',
    detail: '获取设备模型属性列表.\n' +
        '参数：\n' +
        'deviceTypeName为设备类型名称字符串\n' +
        '\n' +
        '返回：\n' +
        '若获取成功则返回PropList类型的对象，失败则返回null。'
}, {
    label: 'GetDriverRefNode(refNodeName: string)',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'GetDriverRefNode(${1:refNodeName})',
    detail: '根据引用节点名，获取真正的设备节点.\n' +
        '参数：\n' +
        'refNodeName为引用节点名称\n' +
        '\n' +
        '返回：\n' +
        '若获取成功则返回NodeTemplateMaker对象，失败则返回null。'
}, {
    label: 'GetDeviceData(deviceName: string)',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'GetDeviceData(${1:deviceName})',
    detail: '获取设备的数据节点.\n' +
        '参数：\n' +
        'deviceName为设备名称字符串\n' +
        '\n' +
        ' \n' +
        '返回：\n' +
        '若找到设备则返回NodeTemplateMaker对象，失败则返回null。'
}, {
    label: 'CreatTCPComm(IP: string,port: number,timeout: number)',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'CreatTCPComm(${1:IP},${2:port},${3:timeout})',
    detail: '创建tcp通讯对象.\n' +
        '参数：\n' +
        'IP：IP地址，类型：字符串\n' +
        'port：端口号，类型：int\n' +
        'timeout：接收超时时间，类型：int\n' +
        '返回：\n' +
        '若成功则返回ICommAgent对象，失败则返回null。\n' +
        '注意:\n' +
        '由脚本创建的通讯对象不再使用时调用CommObject的Release()函数进行释放，否则将会发生内存泄漏   '
}, {
    label: 'CreatRS232Comm(string portName,int baudRate,int parity,string stopBits,int byteSize, int recvTimeOut)',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'CreatRS232Comm(${1:portName},${2:baudRate},${3:parity},${4:stopBits},${5:byteSize},${6:recvTimeOut})',
    detail: '创建RS232通讯对象.\n' +
        '参数：\n' +
        'portName：串口号，类型：string\n' +
        'baudRate：波特率，类型：int\n' +
        'parity：校验位，0——无校验，1——设置奇偶校验位，以便设置了位数为奇数 2——设置奇偶校验位，以便设置了位的计数为偶数，3——将奇偶校验位设置为 1，4——将奇偶校验位设置为 0，类型：int                                    stopBits:停止位，"0"——无停止位  "1"——1个停止位，"1.5"——1.5个停止位，"2"——2个停止位                 byteSize：位数                                 timeout：接收超时时间，类型：int                     返回：\n' +
        '若成功则返回ICommAgent对象，失败则返回null。\n' +
        '注意:\n' +
        '由脚本创建的通讯对象不再使用时调用CommObject的Release()函数进行释放，否则将会发生内存泄漏   '
}, {
    label: 'CreatHttpClient()',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'CreatHttpClient()',
    detail: '创建HttpClient对象.\n' +
        '返回：HttpTemplateMaker对象'
}, {
    label: 'GetCommModelPropList(String typeName)',
    kind: monaco.languages.CompletionItemKind.Method,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    insertText: 'GetCommModelPropList(${1:typeName})',
    detail: '获取通讯模型属性列表.\n' +
        '参数：\n' +
        'typeName为通讯类型名字符串\n' +
        '\n' +
        '返回：\n' +
        '若成功则返回通讯模型属性列表PropList对象，失败则返回null.'
}]
